import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { gsap } from 'gsap'
import { BooleanKeyframeTrack, Raycaster } from 'three'


/**
 * Base
 */
// Debug

var model
//var action
var book, page, page1, page2, page3, laptop

let player = document.getElementById("player")
//let progress = document.getElementById("progress")
let playbtn = document.getElementById("playbtn")
let btn1 = document.getElementById("btn1")
let btn2 = document.getElementById("btn2")

player.volume = 0.3

var btnBack = function () {
    reset()

    object1.position.x = -0.9
    object1.position.y = 1.25
    object1.position.z = 1.7

    laptop.stop()
    hideResume()
    hideResume2()
}

function reset() {
    controls.enabled = true
    gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: -8, y: 5, z: 7})
    gsap.to(model.position, { duration: 0.3, delay: 0.7, x: -1, y: 0, z: 0.2 })

    

    object1.position.x = -0.9
    object1.position.y = 1.25
    object1.position.z = 1.7

    object2.position.x = 1.1
    object2.position.y = 1.3
    object2.position.z = 0.43

    
    object3.position.x = 3.5
    object3.position.y = 10
    object3.position.z = 1.3
}



var playpause = function() {
    if (player.paused) {
        player.play()
    }else {
        player.pause()
        
    }
}

btn1.addEventListener("click", btnBack)

btn2.addEventListener("click", btnBack)

playbtn.addEventListener("click", playpause)


player.onplay = function() {
    playbtn.classList.remove("fa-play")
    playbtn.classList.add("fa-pause")
    book.play()
    page.play()
    page1.play()
    page2.play()
    page3.play()

}

player.onpause = function() {
    playbtn.classList.remove("fa-pause")
    playbtn.classList.add("fa-play")
    book.stop()
    page.stop()
    page1.stop()
    page2.stop()
    page3.stop()
}




let copyText = document.querySelector(".copy-text")
copyText.querySelector("button").addEventListener("click", function(){
    let input = copyText.querySelector("input.text")
    input.select()
    document.execCommand("copy")
    copyText.classList.add("active")
    window.getSelection().removeAllRanges()
    setTimeout(function(){
        copyText.classList.remove("active")
    },2500)
})


let sceneReady = false
const loadingBarElement = document.querySelector('.loading-bar')
const loadingManager = new THREE.LoadingManager(
    () =>
    {
        gsap.delayedCall(0.5, () => {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0 })
            loadingBarElement.style.display = 'none' 
            newFunction()
            
            //loadingBarElement.classList.add('ended')
            //loadingBarElement.style.transform = ''
        })

        window.setTimeout(() => {
            sceneReady = true
              
        }, 2000)
    },


    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        const progressRatio = itemsLoaded / itemsTotal
        //loadingBarElement.style.transform = 'scaleX(' + progressRatio + ')'
   
    }

)

function newFunction(){
    var player1 = document.getElementById("player1")
    player1.classList.add("zealous")
}

function showResume(){
    setTimeout(function(){ startTimer(); }, 2000);
    var resume = document.getElementById("resume1")
    resume.classList.add("showcase")
}

function showResume2(){
    setTimeout(function(){ startTimer(); }, 2000);
    var resume2 = document.getElementById("resume2")
    resume2.classList.add("showcase")
}

function hideResume2(){
    var resume2 = document.getElementById("resume2")
    resume2.classList.remove("showcase")
}


function hideResume(){
    var resume = document.getElementById("resume1")
    resume.classList.remove("showcase")
}



const video = document.getElementById('video')
const texture = new THREE.VideoTexture(video)
const picTexture = new THREE.TextureLoader().load("/textures/picture/homeLogo.jpeg")
const picTexture2 = new THREE.TextureLoader().load("/textures/picture/homeHover.jpeg")
const tvTexture = new THREE.TextureLoader().load("/textures/picture/tv_screen.png")
const comingTexture = new THREE.TextureLoader().load("/textures/picture/coming.png")
const blueTexture = new THREE.MeshBasicMaterial({ color: 0xff0000 })


texture.minFilter = THREE.LinearFilter;
texture.magFilter = THREE.LinearFilter;

var movieMaterial = new THREE.MeshBasicMaterial({
    map: texture,
    side: THREE.FrontSide,
    toneMapped: false,
})


var picMaterial = new THREE.MeshBasicMaterial({
    map: picTexture,
    toneMapped: false,
    transparent: true,
    opacity: 1
})



var picMaterialh = new THREE.MeshBasicMaterial({
    map: picTexture2,
    side: THREE.FrontSide,
    toneMapped: false,
    transparent: true,
    opacity: 1
})

var tvpicMaterial = new THREE.MeshBasicMaterial({
    map: tvTexture,
    toneMapped: false,
    transparent: true,
    opacity: 1
})

var comingMaterial = new THREE.MeshBasicMaterial({
    map: comingTexture,
    side: THREE.FrontSide,
    toneMapped: false,
    transparent: true,
    opacity: 1
})







// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()




/**
 * overlay
 * */
const overlayGeometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    // wireframe: true,
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 }
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)


 // Models
 
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)

const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)



// Point of Interest
const raycaster = new Raycaster()

const points = [
    {
        position: new THREE.Vector3(-0.8, -0.3, -0.5),
        element: document.querySelector('.point-0')
    }
]




let mixer 
var children = []
gltfLoader.load(
    '/models/room13.glb',
    (gltf) => {

        model = gltf.scene
        mixer = new THREE.AnimationMixer(model)
        mixer.clipAction(gltf.animations[1]).play()
        mixer.clipAction(gltf.animations[7]).play()
        book = mixer.clipAction(gltf.animations[2])
        page = mixer.clipAction(gltf.animations[3])
        page1 = mixer.clipAction(gltf.animations[4])
        page2 = mixer.clipAction(gltf.animations[5])
        page3 = mixer.clipAction(gltf.animations[6])
        laptop = mixer.clipAction(gltf.animations[0])
        

        
        laptop.setLoop(THREE.LoopOnce)
        laptop.clampWhenFinished = true

        /*mixer.clipAction(gltf.animations[2]).play()
        mixer.clipAction(gltf.animations[3]).play()*/



        model.traverse(function (node) {
            if (node.isMesh) {
                node.castShadow = true
                node.material.environmentMap = environmentMap
                children.push(node)
            }
        })
        model.rotation.y = Math.PI * -0.1
        model.position.x = -1
        model.position.z = 0.2
        //console.log(model.children[1])
        console.log(model)
        console.log(mixer)
        scene.add(model)


    })



/**
 * Objects
 */
const object1 = new THREE.Mesh(
    new THREE.BoxBufferGeometry(0.4, 0.3, 0.4),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)
object1.position.x = -0.9
object1.position.y = 1.25
object1.position.z = 1.7
object1.material = texture



const object2 = new THREE.Mesh(
    new THREE.BoxBufferGeometry(0.8, 2.1, 1.1),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)

object2.position.y = 1.3
object2.position.x = 1.1
object2.position.z = 0.43
object2.rotation.y = Math.PI * -0.1

object2.material = texture

const object3 = new THREE.Mesh(
    new THREE.BoxBufferGeometry(0.2, 0.1, 0.2),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)
object3.position.x = 3.5
object3.position.y = 10
object3.position.z = 1.3
object3.rotation.y = Math.PI * -0.1
object3.material = texture

const object6 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.1, 10, 10),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)

object6.position.x = -1.3
object6.position.y = 13
object6.position.z = 1
object6.material = texture

const object7 = new THREE.Mesh(
    new THREE.BoxBufferGeometry(0.3, 0.6, 0.6),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)

object7.position.y = 0.3
object7.position.x = -2.2
object7.position.z = -1.4
object7.rotation.y = Math.PI * -0.15
object7.material = texture

const object4 = new THREE.Mesh(
    new THREE.PlaneBufferGeometry( 2.56, 1.15),
    //texture
    //textureR
    //new THREE.MeshBasicMaterial({ color: '#ff0000' })
    movieMaterial
)
object4.position.y = 3.18

object4.position.z = 0.88
object4.rotation.y = Math.PI * -1.6
object4.position.x = 0.77




const object5 = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(0.8, 0.9),
    //texture
    //new THREE.MeshBasicMaterial({ color: '#000000' })
    //picMaterial
)
object5.position.y = 1.3
object5.position.x = 0.1
object5.position.z = 0
//object5.scale.x = 1
//object5.scale.y = 2

object5.rotation.Y =  Math.PI / 2

const object8 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.3, 10, 10),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)

object8.position.y = 0.2
object8.position.x = 0.7
object8.position.z = 0.27
object8.material = texture

const object9 = new THREE.Mesh(
    new THREE.BoxBufferGeometry(0.5, 0.5, 0.4),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)

object9.position.y = 1
object9.position.x = -1.63
object9.position.z = -1.5
object9.rotation.y = Math.PI * -0.1
object9.material = texture
 

const object10 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.2, 0.2),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)
object10.position.y = 5
object10.position.x = -0.7
object10.position.z = 0.4
object10.material = texture

const object11 = new THREE.Mesh(
    new THREE.BoxBufferGeometry(0.5, 0.7, 0.4),
    new THREE.MeshBasicMaterial({ color: '#ff0000' })
)
object11.position.y = 0.3
object11.position.x = -1.63
object11.position.z = -1.5
object11.rotation.y = Math.PI * -0.1
object11.material = texture

const object12 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.6, 8, 8),
    texture
)
object12.position.y = 0.8
object12.position.x = -1.8
object12.position.z = -1.3
//object11.material = texture

const object13 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.1, 8, 8),
    texture
)
object13.position.y = 5
object13.position.x = 0
object13.position.z = 1
//object11.material = texture

const object14 = new THREE.Mesh(
    new THREE.SphereBufferGeometry(0.1, 8, 8),
    texture
)
object14.position.y = 5
object14.position.x = 0.2
object14.position.z = 1


scene.add(object1, object2, object3, object4, object5, object6, object7, object8, object9, object10, object11, object12, object13, object14)

/**
 * Update all materials
 */
const updateAllMaterials = () => {
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            // child.material.envMap = environmentMap
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true

            children.material.needsUpdate = true
            children.castShadow = true
            children.receiveShadow = true
        }
    })
}

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/1/px.png',
    '/textures/environmentMaps/1/nx.png',
    '/textures/environmentMaps/1/py.png',
    '/textures/environmentMaps/1/ny.png',
    '/textures/environmentMaps/1/pz.png',
    '/textures/environmentMaps/1/nz.png'
])

environmentMap.encoding = THREE.sRGBEncoding

scene.background = environmentMap
scene.environment = environmentMap
scene.rotation.y = Math.PI * 0.9

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 9999
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(5, 5, 5)
scene.add(directionalLight)

const light = new THREE.DirectionalLight(0xffffff, 0.5, 100)
light.position.set(0, 10, 0)
light.castShadow = true
light.shadow.camera.near = 0.1
light.shadow.bias = -0.001
scene.add(light)




/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 99999)
camera.position.set(- 8, 5, 7)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)

controls.target.set(0, 0.5, 0)
controls.enableDamping = true
controls.minDistance = 3
controls.maxDistance = 20
controls.maxPolarAngle = Math.PI / 2
controls.enablePan = false



// Click Event on Meshes
window.addEventListener('click', () => {

    if (currentIntersect) {
        if (currentIntersect.object === object1) {
            console.log('1')
            
            //setTimeout(function() { console.log("test1"); },5000)

            // you can easily disable it by using
            controls.enabled = false

            gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: -0.85, y: 1.5, z: 2.5})
            gsap.to(model.position, { duration: 0.5, delay: 0.5, x: -0.7, y: 0, z: 0.2})
            object7.position.y = 5
            object1.position.y = 5
            object10.position.y = 0.4

            
            laptop.play()

            
            const myTimeout = setTimeout(showResume, 1000)
            myTimeout()
            showResume()
            
            

            /*object4.position.x = -0.1
            object4.position.y = 0.73
            model.position.x = -2
            model.position.y = -0.2*/

            controls.minDistance = 0

            controls.maxPolarAngle = 0

            //Movement
            //camera.position.set(-1, -2, 0)
            controls.maxPolarAngle = Math.PI / 2
        }
        else if (currentIntersect.object === object3) {
            reset()
            // you can easily disable it by using
            //controls.enabled = false
            /*
            if ( book != null ) {
                //action.stop()
                //action.play()
                book.stop()
                book.play()
            }*/

            
            object3.position.y = 10
            //gsap.to(camera.position, { duration: 0.3, delay: 0.2, x: 0, y: 4, z: 0.1})
            //camera.position.set(0, 4, 0)
            //model.position.set(2.35, 9.5, -1)
            //object6.position.set(0, 10, 0)
        } 
        else if (currentIntersect.object === object7) {
            console.log('7')
            window.open('https://www.instagram.com/j.son.art/')

        }  
        else if (currentIntersect.object === object11) {
            console.log('11')
            window.open('https://www.linkedin.com/in/jason-lee-73a099120/')

        } 
        else if (currentIntersect.object === object9) {
            console.log('9')
            
            window.open('https://jasonism.wixsite.com/jason-portfolio')

        } 
        else if (currentIntersect.object === object12) {
            
            //controls.minDistance = 3
            
            // you can easily disable it by using
            //controls.enabled = false

            /*gsap.to(camera.position, { duration: 1, delay: 0.5, x: 0, y: 0, z: 2})
            gsap.to(model.position, { duration: 0.5, delay: 0.5, x: 2.7, y: -0.4, z: 2.2 })
            object12.position.y = 10
            object13.position.y = 0.4
            object14.position.y = 0.3*/
            
        }
        else if (currentIntersect.object === object14){

            
            // you can easily disable it by using
            //controls.enabled = true

            for (const point of points) {
                
                //point.element.classList.remove('visible')  
                
            }

            
            /*gsap.to(camera.position, { duration: 0.5, delay: 0.9, x: -8, y: 5, z: 7})
            gsap.to(model.position, { duration: 0.4, delay: 0.7, x: 1, y: 0, z: 0.02 })
            gsap.to(object4.position, { duration: 0.1, delay: 0.4, x: 2.9, y: 0.93, z: 0 })
            gsap.to(object5.position, { duration: 0.6, delay: 0.5, x: 0.1, y: 1.3, z: -1.5 })

            
            object12.position.y = 0.8
            object13.position.y = 5
            object14.position.y = 5

            
            controls.target.set(0, 0.5, 0)*/
        }

        else if (currentIntersect.object === object13) {


            
            for (const point of points) {
                
                    //point.element.classList.add('visible')
            }
        }

        else if (currentIntersect.object === object8) {

            video.play();
				video.addEventListener( 'play', function () {

					this.currentTime = 3;

				}, false );

        }
        else if (currentIntersect.object === object2) {
            console.log('2')

            controls.enabled = false
            
            gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: 5, y: 2, z: 2})
            gsap.to(model.position, { duration: 0.5, delay: 0.5, x: 1, y: -0.2, z: 1})
            object3.position.y = 1.1

        }
        /*else if (currentIntersect.object === object10) {
            console.log('4')
            controls.enabled = true
            object4.material = movieMaterial
            
            setTimeout(function() { console.log("test4"); },7000)
            gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: -8, y: 5, z: 7})
            gsap.to(model.position, { duration: 0.3, delay: 0.7, x: 1, y: 0, z: 0.02 })
            gsap.to(object4.position, { duration: 0.1, delay: 0.4, x: 2.9, y: 0.93, z: 0 })
            gsap.to(object5.position, { duration: 1, delay: 1, x: 0.1, y: 1.3, z: -1.5 })
            object7.position.y = 0.2
            object1.position.y = 1
            object10.position.y = 0.4


            /*model.position.x = 1
            object4.position.y = 0.93
            object4.position.x = 2.9
            model.position.y = 0
            // you can easily disable it by using
            controls.enabled = true
            //camera.position.set(- 8, 5, 7)
            controls.target.set(0, 0.5, 0)
            controls.minDistance = 10
            controls.maxPolarAngle = Math.PI / 2
            controls.enablePan = false
        }*/
        else if (currentIntersect.object === object5) {
            console.log('5')
            object5.material = picMaterialh
            //Click Event
        }
        
        else if (currentIntersect.object === object6) {
            console.log('6')
            //Click Event
            controls.enabled = true

            /*book.stop()
            //book.play()
            page1.stop()
            //page1.play()
            page2.stop()
            //page2.play()
            page3.stop()
            //page3.play()
            page4.stop()
            //page4.play()
            page5.stop()
            //page5.play()
            page6.stop()
            //page6.play()
            page7.stop()
            //page7.play()
            page8.stop()
            //page8.play()
            page9.stop()
            //page9.play()
            page10.stop()
            //page10.play()
            page11.stop()*/
            //page11.play()
            //page12.stop()

            
            setTimeout(function() { console.log("test6"); },4000)
            gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: -8, y: 5, z: 7})
            gsap.to(model.position, { duration: 0.3, delay: 0.7, x: 1, y: 0, z: 0.02 })
            gsap.to(object4.position, { duration: 1, delay: 1, x: 2.9, y: 0.93, z: 0 })
            gsap.to(object5.position, { duration: 1, delay: 1, x: 0.1, y: 1.3, z: -1.5 })
            object3.position.y = 0
            object6.position.x = -1.3
            object6.position.y = 13
            object6.position.z = 1

            

            /*object5.position.y = 1.3
            object5.position.x = 0.1
            object5.position.z = -1.57
            
            model.position.x = 1
            model.position.z = 0.02
            object4.position.y = 0.93
            object4.position.x = 2.9
            model.position.y = 0*/
            // you can easily disable it by using
            controls.enabled = true
            //camera.position.set(- 8, 5, 7)
            controls.target.set(0, 0.5, 0)
            controls.minDistance = 10
            controls.maxPolarAngle = Math.PI / 2
        }

        else if (currentIntersect.object === object4) {
            console.log('4')
            newFunction()
            
        }

    }
})

window.addEventListener('touchstart', () => {

    if (currentIntersect) {
        if (currentIntersect.object === object1) {
            console.log('1')
            
            //setTimeout(function() { console.log("test1"); },5000)

            // you can easily disable it by using
            controls.enabled = false

            gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: -0.85, y: 1.5, z: 2.5})
            gsap.to(model.position, { duration: 0.5, delay: 0.5, x: -0.7, y: 0, z: 0.2})
            object7.position.y = 5
            object1.position.y = 5
            object10.position.y = 0.4

            
            laptop.play()

            
            const myTimeout = setTimeout(showResume, 1000)
            myTimeout()
            showResume()
            
            

            /*object4.position.x = -0.1
            object4.position.y = 0.73
            model.position.x = -2
            model.position.y = -0.2*/

            controls.minDistance = 0

            controls.maxPolarAngle = 0

            //Movement
            //camera.position.set(-1, -2, 0)
            controls.maxPolarAngle = Math.PI / 2
        }
        else if (currentIntersect.object === object3) {
            reset()
            // you can easily disable it by using
            //controls.enabled = false
            /*
            if ( book != null ) {
                //action.stop()
                //action.play()
                book.stop()
                book.play()
            }*/

            
            object3.position.y = 10
            //gsap.to(camera.position, { duration: 0.3, delay: 0.2, x: 0, y: 4, z: 0.1})
            //camera.position.set(0, 4, 0)
            //model.position.set(2.35, 9.5, -1)
            //object6.position.set(0, 10, 0)
        } 
        else if (currentIntersect.object === object7) {
            console.log('7')
            window.open('https://www.instagram.com/j.son.art/')

        }  
        else if (currentIntersect.object === object11) {
            console.log('11')
            window.open('https://www.linkedin.com/in/jason-lee-73a099120/')

        } 
        else if (currentIntersect.object === object9) {
            console.log('9')
            
            window.open('https://jasonism.wixsite.com/jason-portfolio')

        } 
        else if (currentIntersect.object === object12) {
            
            //controls.minDistance = 3
            
            // you can easily disable it by using
            //controls.enabled = false

            /*gsap.to(camera.position, { duration: 1, delay: 0.5, x: 0, y: 0, z: 2})
            gsap.to(model.position, { duration: 0.5, delay: 0.5, x: 2.7, y: -0.4, z: 2.2 })
            object12.position.y = 10
            object13.position.y = 0.4
            object14.position.y = 0.3*/
            
        }
        else if (currentIntersect.object === object14){

            
            // you can easily disable it by using
            //controls.enabled = true

            for (const point of points) {
                
                //point.element.classList.remove('visible')  
                
            }

            
            /*gsap.to(camera.position, { duration: 0.5, delay: 0.9, x: -8, y: 5, z: 7})
            gsap.to(model.position, { duration: 0.4, delay: 0.7, x: 1, y: 0, z: 0.02 })
            gsap.to(object4.position, { duration: 0.1, delay: 0.4, x: 2.9, y: 0.93, z: 0 })
            gsap.to(object5.position, { duration: 0.6, delay: 0.5, x: 0.1, y: 1.3, z: -1.5 })

            
            object12.position.y = 0.8
            object13.position.y = 5
            object14.position.y = 5

            
            controls.target.set(0, 0.5, 0)*/
        }

        else if (currentIntersect.object === object13) {


            
            for (const point of points) {
                
                    //point.element.classList.add('visible')
            }
        }

        else if (currentIntersect.object === object8) {

            video.play();
				video.addEventListener( 'play', function () {

					this.currentTime = 3;

				}, false );

        }
        else if (currentIntersect.object === object2) {
            console.log('2')

            controls.enabled = false
            
            gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: 5, y: 2, z: 2})
            gsap.to(model.position, { duration: 0.5, delay: 0.5, x: 1, y: -0.2, z: 1})
            object3.position.y = 1.1

        }
        /*else if (currentIntersect.object === object10) {
            console.log('4')
            controls.enabled = true
            object4.material = movieMaterial
            
            setTimeout(function() { console.log("test4"); },7000)
            gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: -8, y: 5, z: 7})
            gsap.to(model.position, { duration: 0.3, delay: 0.7, x: 1, y: 0, z: 0.02 })
            gsap.to(object4.position, { duration: 0.1, delay: 0.4, x: 2.9, y: 0.93, z: 0 })
            gsap.to(object5.position, { duration: 1, delay: 1, x: 0.1, y: 1.3, z: -1.5 })
            object7.position.y = 0.2
            object1.position.y = 1
            object10.position.y = 0.4


            /*model.position.x = 1
            object4.position.y = 0.93
            object4.position.x = 2.9
            model.position.y = 0
            // you can easily disable it by using
            controls.enabled = true
            //camera.position.set(- 8, 5, 7)
            controls.target.set(0, 0.5, 0)
            controls.minDistance = 10
            controls.maxPolarAngle = Math.PI / 2
            controls.enablePan = false
        }*/
        else if (currentIntersect.object === object5) {
            console.log('5')
            object5.material = picMaterialh
            //Click Event
        }
        
        else if (currentIntersect.object === object6) {
            console.log('6')
            //Click Event
            controls.enabled = true

            /*book.stop()
            //book.play()
            page1.stop()
            //page1.play()
            page2.stop()
            //page2.play()
            page3.stop()
            //page3.play()
            page4.stop()
            //page4.play()
            page5.stop()
            //page5.play()
            page6.stop()
            //page6.play()
            page7.stop()
            //page7.play()
            page8.stop()
            //page8.play()
            page9.stop()
            //page9.play()
            page10.stop()
            //page10.play()
            page11.stop()*/
            //page11.play()
            //page12.stop()

            
            setTimeout(function() { console.log("test6"); },4000)
            gsap.to(camera.position, { duration: 0.5, delay: 0.5, x: -8, y: 5, z: 7})
            gsap.to(model.position, { duration: 0.3, delay: 0.7, x: 1, y: 0, z: 0.02 })
            gsap.to(object4.position, { duration: 1, delay: 1, x: 2.9, y: 0.93, z: 0 })
            gsap.to(object5.position, { duration: 1, delay: 1, x: 0.1, y: 1.3, z: -1.5 })
            object3.position.y = 0
            object6.position.x = -1.3
            object6.position.y = 13
            object6.position.z = 1

            

            /*object5.position.y = 1.3
            object5.position.x = 0.1
            object5.position.z = -1.57
            
            model.position.x = 1
            model.position.z = 0.02
            object4.position.y = 0.93
            object4.position.x = 2.9
            model.position.y = 0*/
            // you can easily disable it by using
            controls.enabled = true
            //camera.position.set(- 8, 5, 7)
            controls.target.set(0, 0.5, 0)
            controls.minDistance = 10
            controls.maxPolarAngle = Math.PI / 2
        }

        else if (currentIntersect.object === object4) {
            console.log('4')
            newFunction()
            
        }

    }
})



/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: false
})
//renderer.shadowMap.type = true
//renderer.shadowMap.enabled = true
//renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
//renderer.physicallyCorrectLights = true
//enderer.gammaOutput = true
//renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.NoToneMapping
renderer.exposure = 0

var pmremGenerator = new THREE.PMREMGenerator(renderer);
pmremGenerator.compileEquirectangularShader();




/**
 * Animate
 */
animate()

function animate() {
    requestAnimationFrame(animate)
    texture.needsUpdate = true
    if (mixer) mixer.update(clock.getDelta())


}

const pointer = new THREE.Vector2()

function onPointerMove(event) {
    pointer.x = (event.clientX / window.innerWidth) *  2 - 1
    pointer.y = - (event.clientY / window.innerHeight) * 2 + 1
}



const clock = new THREE.Clock()
let previousTime = 0
let currentIntersect = null


const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    if(mixer)
    {
        mixer.update(deltaTime)
    }


    // Update controls
    controls.update()


    if (sceneReady) {


        //Go through each point
        for (const point of points) {
            const screenPosition = point.position.clone()
            screenPosition.project(camera)


            raycaster.setFromCamera(pointer, camera)
            const objectsToTest = [object5]
            const intersects = raycaster.intersectObjects(scene.children, true)


            for (const object of objectsToTest) {
                if (object   === object5) {
                    object5.material = texture
                    //object5.material = picMaterial
                }
            }

            for (const intersect of intersects) {
                if (intersect.object === object5) {
                    object5.material = texture
                    //object5.material = picMaterialh
                }
            }

            

            

            if (intersects.length) {
                if (currentIntersect === null) {
                    //console.log('mouse enter')
                }
                currentIntersect = intersects[0]
            }
            else {
                if (currentIntersect) {
                    //console.log('mouse leave')
                }

                currentIntersect = null
            }

            /*
            if (intersects.length === 0) {
                point.element.classList.add('visible')
            }
            else {
                const intersectionDistance = intersects[0].distance
                const pointDistance = point.position.distanceTo(camera.position)

                if (intersectionDistance < pointDistance) {
                    point.element.classList.remove('visible')
                }
                else {
                    point.element.classList.add('visible')
                }
                point.element.classList.remove('visible')
            }

            const translateX = screenPosition.x * sizes.width * 0.5
            const translateY = - screenPosition.y * sizes.height * 0.5
            point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
            */
        }

    }
    

    // Render
    renderer.render(scene, camera)


    //for pointer
    window.addEventListener('pointermove', onPointerMove)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}


tick()